import React from 'react';
import { useLocation } from 'react-router-dom';
import { Entry, EntryFields, Asset } from 'contentful';
import {
  useSetPageTitle,
  useMoveFocusToPageH1,
  KSkipLink,
  KBackToTopLink,
} from '@xenzonegroup/kompass';
import { mapContentTypeToComponent } from '../component-mapper';
import { NavigationBarType } from '../NavigationMenu/NavigationBar';
import Header from '../Header';
import { FullPageLoadingSpinner } from '../LoadingSpinner';
import Footer, { FooterType } from '../Footer';
import { ErrorLoadingPage } from '../../pages/ErrorLoadingPage';
import { useGetContent } from '../../content/useGetContent';
import { getContentUrl } from '../../content/getContentUrl';

import styles from './page-wrapper.scss';
import { useSetMetaProperty } from '../../utils';
import configProvider from '../../utils/configProvider';
import { useGetVisitorLocationCountryFromHtmlMetaTag } from '../../utils/useGetVisitorLocationCountryFromHtmlMetaTag';
import { GoToUsSiteBanner } from '../GoToUsSiteBanner';

export interface PageContainingNavigationBarAndFooter {
  navigationBar: Entry<NavigationBarType>;
  footer: Entry<FooterType>;
  metaTitle?: EntryFields.Text;
  metaImage?: Asset;
  seoDescription?: EntryFields.Text;
}

interface PageWrapperProps {
  contentType?: 'article' | 'privacyPage';
  slug?: string;
  contentId?: string;
  serverContent?: Entry<PageContainingNavigationBarAndFooter>;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  contentType,
  slug,
  contentId,
  serverContent,
}) => {
  const { featureFlags } = configProvider.config;
  const location = useLocation<{ shouldMoveFocusToPageH1?: boolean }>();
  const url = getContentUrl({ contentType, slug, contentId });

  const { data: content, isError } = useGetContent<
    Entry<PageContainingNavigationBarAndFooter>
  >(url, serverContent);

  useSetMetaProperty('description', content?.fields.seoDescription);
  useSetMetaProperty('title', content?.fields.metaTitle);
  useSetMetaProperty('image', content?.fields.metaImage?.fields.file.url);

  const getPageTitle = (): string => {
    const append = 'Kooth plc';
    if (isError) {
      return 'Problem loading this page' + ' - ' + append;
    }
    if (content?.fields.metaTitle) {
      return content?.fields.metaTitle + ' - ' + append;
    }
    return append;
  };

  useSetPageTitle({
    pageTitle: getPageTitle(),
    dependenciesArray: [content?.fields.metaTitle, isError],
    ignoreH1Errors: true,
    append: null,
  });

  useMoveFocusToPageH1({
    dependenciesArray: [content, location.state?.shouldMoveFocusToPageH1],
    disableEffect: !content || !location.state?.shouldMoveFocusToPageH1,
  });

  const visitorLocationCountry = useGetVisitorLocationCountryFromHtmlMetaTag();

  if (isError) {
    return <ErrorLoadingPage />;
  }

  if (!content) {
    return <FullPageLoadingSpinner />;
  }

  const Component = mapContentTypeToComponent(content?.sys.contentType.sys.id);

  return (
    <>
      <KSkipLink />

      {visitorLocationCountry === 'us' ? <GoToUsSiteBanner /> : null}

      <Header content={content?.fields.navigationBar} />
      <main
        id="main_content"
        className={`${styles.content} ${content?.sys.contentType.sys.id}`}
      >
        <Component content={content} />
      </main>
      <Footer content={content?.fields.footer} />
      {featureFlags.scrollToTop && <KBackToTopLink />}
    </>
  );
};

export default PageWrapper;

/**
 * Note on shouldMoveFocusToPageH1:
 *
 * When users activate a React Router Link, we want the keyboard focus to move to the new page's h1. (See Kompass' `useMoveFocusToPageH1` docs for more details).
 *
 * To implement this, React Router Links have `to={{ pathname: ... , state: shouldMoveFocusToPageH1 : true }}`.
 * We look at `location.state.shouldMoveFocusToPageH1` to decide whether to disable the `useMoveFocusToPageH1` effect.
 */
